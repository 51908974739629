import React from 'react';
import { Box, Flex } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { Button, Caption, Label, Text } from '@swibeco/ui';
import {
  BASKET_PAGE,
  createDataLayerDealObject,
  generatePageName,
  useColorVariant,
  useNumberFormatter,
} from '@swibeco/shared';
import { useIsMobile, useIsTablet, usePlatform } from '@swibeco/core';
import { AnalyticsEvents, BaseDeal } from '@swibeco/types';
import { useHistory } from 'react-router-dom';
import { useAddToBasket } from '@swibeco/shared-web';
import { formatSubscriptionData } from '@swibeco/ecommerce';
import TagManager from 'react-gtm-module';

type PlusPlanCardProps = {
  isPopularOffer?: boolean;
  subscription: BaseDeal;
};

const MostPopularTag = () => {
  const { t } = useTranslation();
  return (
    <Box
      position="absolute"
      top="-15px"
      left="50%"
      transform="translate(-50%, -50%)"
      p="5px 20px"
      minW="max-content"
      borderRadius="35px 35px 0px 0px"
      bgColor="complementary.light"
    >
      <Caption color="default.white">
        {t('core.ecommerce.plus.plans.most_popular')}
      </Caption>
    </Box>
  );
};

const MonthsFree = ({ discountText = 'months free' }) => {
  const primaryMain = useColorVariant('primary', 'main');
  return (
    <Box
      maxW="max-content"
      margin="0 auto"
      borderRadius="34px"
      border="2px solid"
      borderColor="primary.main"
      p="6px 16px"
    >
      <Label className="mb-0" color={primaryMain}>{`${discountText}`}</Label>
    </Box>
  );
};

const PlusPlanCard = ({ isPopularOffer, subscription }: PlusPlanCardProps) => {
  const { t } = useTranslation();
  const currencyFormatter = useNumberFormatter();
  const history = useHistory();
  const isTablet = useIsTablet();
  const isMobile = useIsMobile();
  const addToBasket = useAddToBasket();
  const { finalPrice, subscriptionDuration, discount } = subscription;
  const isFreeTrial = finalPrice === 0;
  const pageName = generatePageName(history.location.pathname);
  const environment = usePlatform(window);

  const { durationFormatted, pricePerMonth } = formatSubscriptionData(
    t,
    subscription
  );

  const handleSubscribeButton = async () => {
    TagManager.dataLayer(
      createDataLayerDealObject(
        AnalyticsEvents.ADD_TO_CART,
        environment,
        [subscription],
        1,
        pageName
      )
    );
    // add subscription deal to cart
    await addToBasket.addItem(subscription, 1);
    history.push(BASKET_PAGE); // redirect to basket page
  };

  return (
    <Box
      position="relative"
      flexGrow="1"
      mt={{
        base: isPopularOffer ? '27px' : 0,
        md: 0,
      }}
    >
      {isPopularOffer && <MostPopularTag />}
      <Flex
        h="58px"
        bgColor={
          isPopularOffer
            ? 'complementary.lightGreen'
            : 'primary.decorationLight'
        }
        justifyContent="center"
        alignItems="center"
        sx={
          isPopularOffer
            ? {
                borderTop: '1px solid',
                borderLeft: '1px solid',
                borderRight: '1px solid',
                borderColor: 'complementary.light',
              }
            : {
                border: '1px solid',
                borderColor: 'default.main',
              }
        }
      >
        <Text important>
          {isFreeTrial
            ? `${subscriptionDuration} ${t(
                'core.ecommerce.plus.plans.days_trial'
              )}`
            : durationFormatted}
        </Text>
      </Flex>
      <Flex
        flexDir="column"
        minH={{ base: '198px', lg: '238px' }}
        bgColor="white"
        sx={
          isPopularOffer
            ? {
                borderBottom: '1px solid',
                borderLeft: '1px solid',
                borderRight: '1px solid',
                borderColor: 'complementary.light',
              }
            : {
                border: '1px solid',
                borderColor: 'default.main',
              }
        }
        paddingX="20px"
        justifyContent="center"
        alignItems="center"
      >
        <Box marginTop="auto" marginBottom="0">
          <Flex
            justifyContent="center"
            alignItems="baseline"
            gap="4px"
            mb="8px"
            flexWrap="wrap"
          >
            <Text fontSize="30px" lineHeight="39px">
              {isFreeTrial
                ? t('core.ecommerce.plus.plans.free')
                : currencyFormatter(finalPrice)}
            </Text>
            <Text>
              {t('core.ecommerce.plus.plans.for')} {durationFormatted}
            </Text>
          </Flex>
          {discount && <MonthsFree discountText={discount} />}
        </Box>
        <Box marginTop="auto" marginBottom="17px">
          <Button
            disabled={addToBasket.isLoading}
            style={{
              paddingLeft: '16px',
              paddingRight: '16px',
              height: '45px',
              marginBottom: '10px',
              ...(isTablet && !isMobile && { fontSize: '13px' }),
            }}
            color="primary"
            onClick={handleSubscribeButton}
          >
            {isFreeTrial
              ? t('core.ecommerce.plus.plans.activate_free_trial')
              : `${t(
                  'core.ecommerce.plus.plans.subscribe_for'
                )} ${durationFormatted}`}
          </Button>
          <Caption style={{ minHeight: '21px' }}>
            {Boolean(pricePerMonth) &&
              `= CHF ${pricePerMonth}/${t('core.ecommerce.plus.plans.month')}`}
          </Caption>
        </Box>
      </Flex>
    </Box>
  );
};

export default PlusPlanCard;
