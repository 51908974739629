import { Box, Center, Flex, Image } from '@chakra-ui/react';
import React, { useMemo } from 'react';
import { ORDERS, isPremiumUser, useColorVariant } from '@swibeco/shared';
import { Button, Caption, Heading, Text } from '@swibeco/ui';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@emotion/react';
import { useHistory } from 'react-router-dom';
import { format, parseISO } from 'date-fns';
import { useUserSubscription } from '@swibeco/ecommerce';
import plusBanner from '../../../assets/images/plus_banner.png';
import plusBannerTablet from '../../../assets/images/plus_banner_tablet.png';
import plusBannerMobile from '../../../assets/images/plus_banner_mobile.png';
import { ReactComponent as SwibecoPlusLogo } from '../../../assets/svgs/swibeco_plus.svg';
import plusArrows from '../../../assets/images/plus_arrows.gif';
import { ReactComponent as InvoiceIcon } from '../../../assets/svgs/invoice.svg';
import PlusBannerButton from './PlusBannerButton';

type PlusLandingBannerProps = {
  scrollRef?: React.RefObject<HTMLDivElement>;
};

function PlusLandingBanner({ scrollRef }: PlusLandingBannerProps) {
  const white = useColorVariant('default', 'white');
  const { t } = useTranslation();
  const history = useHistory();
  const theme = useTheme();
  const colorScheme = useMemo(
    () => ({
      color: theme.colors.default.white,
      backgroundColor: `${theme.colors.default.white}26`,
      borderColor: 'transparent',
      hoverBorderColor: 'transparent',
      hoverColor: theme.colors.primary.plusBlue,
      hoverBackgroundColor: `${theme.colors.default.white}26`,
      activeBackgroundColor: `${theme.colors.default.white}26`,
      activeColor: theme.colors.default.white,
      disabledColor: theme.colors.default.strong,
      disabledBackgroundColor: 'transparent',
      disabledBorderColor: theme.colors.default.strong,
    }),
    [theme]
  );
  const { subscription } = useUserSubscription();
  const isPremium = subscription && isPremiumUser(subscription.type);
  // keep backednDate as empty string if subscribed_until is null, otherwise resulting Date is falsely flagged as Valid
  const backendDate = subscription?.subscribed_until || '';
  const parsedDate = parseISO(backendDate);
  return (
    <Box position="relative" zIndex={5}>
      <picture>
        <source srcSet={plusBannerMobile} media="(max-width: 499px)" />
        <source srcSet={plusBannerTablet} media="(max-width: 1199px)" />
        <source srcSet={plusBanner} media="(min-width: 1200px)" />
        <Image
          maxH={{
            base: 'auto',
            lg: '463px',
            xl: '463px',
          }}
          minH={{
            base: '586px',
            lg: '586px',
            xl: '530px',
            xxl: '500px',
          }}
          objectFit="cover"
          src={plusBanner}
          w="100%"
          alt="plus banner"
        />
      </picture>
      {!isPremium ? (
        <Flex
          transform="translate(-50%, 0)"
          left="50%"
          top="75px"
          flexDir="column"
          position="absolute"
          gap="16px"
          textAlign="center"
          justifyContent="center"
          alignItems="center"
          minW={{
            base: '321px',
            md: 'auto',
          }}
        >
          <SwibecoPlusLogo />
          <Flex flexDir="column">
            <Heading level="h1" color={white}>
              {t('core.ecommerce.plus.banner.title')}
            </Heading>
          </Flex>

          <Center>
            <Button
              onClick={() =>
                scrollRef?.current?.scrollIntoView?.({ behavior: 'smooth' })
              }
              color="primary"
              lightColor
            >
              {t('core.ecommerce.plus.banner.button')}
            </Button>
          </Center>
          <Box mt="16px">
            <Image src={plusArrows} w="25px" h="43px" />
          </Box>
        </Flex>
      ) : (
        <Flex
          transform="translate(-50%, 0)"
          left="50%"
          top="75px"
          flexDir="column"
          position="absolute"
          gap="16px"
          textAlign="center"
          justifyContent="center"
          alignItems="center"
          minW={{
            base: '321px',
            md: 'auto',
          }}
        >
          <Heading level="h1" color={white}>
            {t('core.ecommerce.plus.banner.member')}
          </Heading>
          <SwibecoPlusLogo />
          <Box w="100%">
            <Flex
              mt="27px"
              p="16px"
              bgColor="primary.plusBlue"
              flexDir="column"
              gap="4px"
            >
              <Caption color="white">
                {t('core.ecommerce.plus.banner.expiration_title')}
              </Caption>
              {!Number.isNaN(parsedDate.getTime()) && (
                <Text bold color="default.white">
                  {format(parsedDate, 'dd MMMM yyyy')}
                </Text>
              )}
            </Flex>
            <PlusBannerButton
              colorScheme={colorScheme}
              color="custom"
              icon={<InvoiceIcon />}
              onClick={() => history.push(ORDERS)}
            >
              {t('core.ecommerce.plus.banner.invoice_button')}
            </PlusBannerButton>
          </Box>
        </Flex>
      )}
    </Box>
  );
}

export default PlusLandingBanner;
